<template>
  <div>
    <div class="consultant-form">
      <div class="row">
        <div  class="form-container col-lg-12 col-12">
          <div class="main-title">获取留学计划</div>
          <div class="divider"></div>
          <b-form class="form-body" @submit="onSubmit">
            <div v-if="submit">
              <b-form-group class="form-field" id="form-name">
                <b-form-input
                    id="form-name-input"
                    v-model="form.name"
                    placeholder="请填写您的姓名"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="form-field" id="form-wechat">
                <b-form-input
                    id="form-wechat-input"
                    v-model="form.wechat"
                    placeholder="您的微信号"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="form-field" id="form-highest_qualification">
                <b-form-select
                    id="form-stage-input"
                    v-model="form.highest_qualification"
                    :options="highest_qualification"
                    required
                ></b-form-select>
              </b-form-group>

              <b-form-group class="form-field" id="form-estimated_date">
                <b-form-select
                    id="form-estimate-time-input"
                    v-model="form.estimated_date"
                    :options="estimated_date"
                    required
                ></b-form-select>
              </b-form-group>

              <b-form-group class="form-field" id="form-requirement">
                <b-form-textarea
                    id="form-requirement"
                    v-model="form.requirement"
                    placeholder="您的留学需求"
                    required
                ></b-form-textarea>
              </b-form-group>

              <div class="btn-wrapper" style="text-align: center">
                <b-button class="submit-btn" type="submit"  variant="primary">提交</b-button>
              </div>
            </div>

            <div class="col-12 order-1 order-lg-2" v-else>
              <h3 style="color: black; text-align: center; padding-top: 30px">正在提交</h3>
              <div style="padding-top: 70px">
                <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="truck" animation="cylon" font-scale="4" shift-v="0"></b-icon>
                <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="three-dots" animation="cylon" font-scale="4"></b-icon>
              </div>
            </div>
          </b-form>

        </div>
	    </div>
      <el-dialog
          title="提交成功"
          style="padding: 0 20px"
          :visible.sync="dialogVisible"
          width="30%">
        <div class="gwInfo">
          <div class="gw-left">
            <img width="60" height="60" src="../assets/myImage/成功.png" alt="">
          </div>
          <div class="text">亲，我们已经收到您的信息！专业的顾问老师会火速与您联系～您也可以添加微信：<strong>EduSg11</strong> 或 <strong>扫码直接咨询我们的老师！</strong></div>
          <div class="er">
            <img width="210" height="200" src="../assets/myImage/guwen.jpg" alt="">
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
      </el-dialog>
	</div>
</div>
</template>

<script>
import { mondayService } from "../services/monday";
import { httpService } from "@/services/http";
import { constants } from "@/services/constants";
import axios from "axios";

export default {
  props: ["items"],
  data() {
    return {
      dialogVisible:false,
      form: {
        name: '',
        wechat: '',
        estimated_date: null,
        requirement: '',
        budget:'',
        phone:'',
        contact_time:'',
        highest_qualification:null,
      },
      highest_qualification: [
        { value: null, text: "选择您的教育程度", disabled: true },
        { value: "幼儿园", text: "幼儿园" },
        { value: "小学", text: "小学" },
        { value: "中学", text: "中学" },
        { value: "高中", text: "高中" },
        { value: "大学", text: "大学" },
        { value: "研究生", text: "研究生" },
        { value: "其他学历", text: "其他学历" },
      ],
      estimated_date: [
        { value: null, text: "选择您的计划留学时间", disabled: true },
        { value: "三个月内", text: "三个月内" },
        { value: "六个月内", text: "六个月内" },
        { value: "一年内", text: "一年内" },
        { value: "明年、之后", text: "明年、之后" },
      ],
      budget : [
        { value: null, text: "选择您的计划留学预算", disabled: true },
        { value: '10万-20万人民币/年', text: '10万-20万人民币/年' },
        { value: '20-30万人民币/年', text: '20-30万人民币/年' },
        { value: '无指定要求', text: '无指定要求' },
      ],
      contact_time:[
        { value: null, text: "选择您的联系时间", disabled: true },
        { value: '上午9-12点', text: '上午9-12点' },
        { value: '下午12-3点', text: '下午12-3点' },
        { value: '下午3-6点', text: '下午3-6点' },
        { value: '下午6点以后', text: '下午6点以后' },
        { value: '任意时间均可', text: '任意时间均可' }
      ],
      submit: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.submit = false
      axios({
        method:'post',
        url:"https://158-liuxue-p.study65.com/api/postStudy65WebStudentEnquiry//",
        headers: {
          'content-type': 'application/json',
          'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
        },
        data: {
          ...this.form
        }
      }).then((res) => {
        console.log(res)
        this.dialogVisible = true//提交完成后
        this.submit = true
        this.form = {
          name: '',
          wechat: '',
          estimated_date: null,
          requirement: '',
          budget:'',
          phone:'',
          contact_time:'',
          highest_qualification:null,
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.form-body{
  border: none!important;
  box-shadow: none!important;
}
	.consultant-form {
		background-color: #FFF;
		width: 24vw;
		// font-size: 23px;

		.col-12 {
			padding: 8px 30px
		}
	}
  .form-field{
    margin-top: 1.5vw;
  }
  .gwInfo{
    margin-top: 0;
    text-align: center;
  }
  .text{
    margin-top: .5vw;
  }
  .gw{
    display: flex;
    justify-content: space-between;
    height: 14vw;
    margin-top: 1vw;
    padding: 0 1vw 2vw;

  }
  .er{
    margin-top: .7vw;
  }

  @media screen and (max-width: 500px) {
    .consultant-form {
      width: 100vw;
    }
  }
</style>
