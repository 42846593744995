<template>
  <div class="container">
    <div class="row message">
      <div class="col-lg-6 col-5" >
        <div style="border-radius: 10px;overflow: hidden;height: 6vw">
          <img width="100%" height="100%" :src="img" alt="">
        </div>
      </div>
      <div class="col-lg-6 col-7" style="margin: auto 0 auto 0px;">
        <h4 @click="toMainUrl(url)">{{ title }}</h4>
        <p style="opacity: .8">{{ createDate }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "schoolNews",
  props:['img','title','url','createDate'],
  methods:{
    toMainUrl(url){//跳转到资讯详情页
      window.open(url,"_blank")
    },
  }
}
</script>

<style scoped>
/**{*/
/*  outline: 1px solid red;*/
/*}*/
h4:hover{
  text-decoration: underline;
  cursor: pointer;
}
.message{
  margin-top: 2vw;
}
@media screen and (max-width: 500px) {

}
</style>