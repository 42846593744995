<template>
  <div>
    <div class="studyGo">
<!--      <studyHead></studyHead>-->
<!--      <h3 style="margin: 3vh 0"> < 返回主页 </h3>-->
      <div class="content">

        <div class="content-left">

          <h3 style="margin-bottom: 2vw">{{ $route.query.name }}</h3>
          <div class="hotContent-right">
            <div class="hot-right" v-for="item of news" :key="item.id">
              <div class="hot-right-left">
                <div style="height: 11vw;width: 85%">
                  <img :src="item.cover_image" alt="">
                </div>
              </div>
              <div class="hot-right-right">
                <h4 @click="toUrl(item.url)">{{ item.article_name }}</h4>
                <p>{{ item.short_intro }}</p>
                <p class="time"><b-icon icon="stopwatch"></b-icon>&nbsp;&nbsp;&nbsp;{{ item.create_date }}</p>
              </div>
            </div>
          </div>
          <div style="text-align: center">
            <el-pagination
                @current-change="handleCurrentChange"
                style="margin: 5vh auto"
                background
                layout="prev, pager, next"
                :total="count">
            </el-pagination>
          </div>
        </div>
        <div class="content-right">
          <h3>{{ title }}</h3>
          <schoolNews :url="item.url" :img="item.cover_image" :title="item.article_name" v-for="item of news2.slice(0,3)"></schoolNews>
          <div style="margin-top: 2vw;">
            <img src="../../assets/myImage/teachyou.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import studyHead from "@/components/studyHead";
import schoolNews from "@/components/schoolNews";
import news from '../../assets/myImage/news.png'
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
import {schoolService} from "@/services/school";

export default {
        components: {
            studyHead,
          schoolNews
        },
      data(){
          return{
            news:'',
            news2:'',
            count:0,
            title:''
          }
      },
      created() {
        let id = parseInt(this.$route.query.id)
        let id2
          httpService.get(constants.BLOGS + `?category=${this.$route.query.id}`).then((data) => {//获取咨讯左侧列表
            console.log(1,data)
            this.news = data.body.results
            this.count = data.body.count
          })
        if (id === 2){//数据库id不联通，加入判断防止报错
          id2 = 4
        }else if(id === 4){
          id2 = 6
        }else if(id === 11){
          id2 = 2
        }else {
          id2 = id + 1
        }
        httpService.get(constants.BLOGS + `?category=${id2}`).then((data) => {//获取右侧咨询列表
          console.log(2,data)
          this.news2 = data.body.results
          this.title = data.body.results[0].category[0].name
        })
      },
      methods:{
        handleCurrentChange(val) {//分页功能
          httpService.get(constants.BLOGS + `?category=${this.$route.query.id}&page=${val}`).then((data) => {
            this.news = data.body.results
          })
          window.scrollTo(0, 0)//点击下一页之后跳回头部
        },
        toUrl(url){//打开资讯
          window.open(url,"_blank")
        }
      }
    }
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
h4:hover{
  cursor: pointer;
  text-decoration: underline;
}
.studyGo{
  width: 80%;
  margin: 0 auto;
  .content{
    display: flex;
    justify-content: space-between;
    margin-top: 3vw;
    .content-left{
      width: 67%;

      .hotContent-right{

        .hot-right{
          background-color: #F8F8F8;
          margin-bottom: 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-items: center;
          .hot-right-left{
            width: 43%;
            height: 11vw;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .hot-right-right{
            padding-right: 3vw;
            width: 57%;
          }
        }
      }
    }
    .content-right{
      width: 29%;
    }
  }
}
</style>

