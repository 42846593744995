<template>
  <div class="knows-top">
    <img height="100%" width="100%" src="../assets/myImage/big.png" alt="">
    <ConsultantForm class="myForm"></ConsultantForm>
  </div>
</template>

<script>
import ConsultantForm from "@/components/ConsultantForm";
export default {
  name: "studyHead",
  components:{
    ConsultantForm
  }
}
</script>

<style scoped>
.knows-top{
  position: relative;
  height: 65vh;
  width: 100%;
}
.myForm{
  position: absolute;
  top: 3%;
  right: 3%;
}
</style>